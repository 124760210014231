import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"
import ImageLayout from "../components/image-layout"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
// import Button from "../components/button"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background/bg-index.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1920) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      picto: file(relativePath: { eq: "icons/icon-1.png" }) {
				childImageSharp {
					fluid(maxWidth: 300) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
      image: file(relativePath: { eq: "image-cabinet.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 600) {
			  			...GatsbyImageSharpFluid_withWebp
					}
				}
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Accueil" />
      <ImageLayout text="Cabinet d'Avocats" image={data.background.childImageSharp.fluid} />
      <div id="home-row">
        <div className="home-row-item" id="left-item">
          <h2 className="row-item-text">DROIT D’AUTEUR</h2>
          <h2 className="row-item-text">PROPRIETE INTELLECTUELLE</h2>
        </div>
        <div className="home-row-item" id="center-item">
          <h2 className="row-item-text">DROIT PUBLIC</h2>
          <h2 className="row-item-text">DROIT DE L’ENVIRONNEMENT</h2>
          <h2 className="row-item-text">DROIT DE L’URBANISME</h2>
        </div>
        <div className="home-row-item" id="right-item">
          <h2 className="row-item-text">DROIT DES MARCHES PUBLICS</h2>
          <h2 className="row-item-text">DROIT INTERNATIONAL / EUROPÉEN</h2>
        </div>
      </div>
      <section className="bg-grey">
        <Grid container>
          <Grid item sm={12} md={7}>
            <Card
              title="essere avocats"
              text="est un cabinet d’avocats implanté à Marseille et à Bandol"
              position="left" />
            <p id="text-home" className="text-primary">Le cabinet intervient principalement en droit public, en droit de l’urbanisme, en droit de l‘environnement et en matière de propriété intellectuelle. Il intervient également sur tous les aspects européens et internationaux des dossiers. Constitué sous la forme d’une AARPI dirigée par deux avocats associés, le cabinet ESSERE AVOCATS offre à sa clientèle un haut niveau de compétence, une réactivité et une disponibilité sur les dossiers qui lui sont confiés. Les avocats du cabinet exercent une activité de conseil et de contentieux. Si chacun des avocats du cabinet a un domaine principal d’activité, l’expérience enseigne qu’il est rare qu’un dossier ne fasse appel qu’à une seule expertise. La complexité croissante du droit et des contentieux exige souvent, de nos jours, une analyse transversale. C’est la raison pour laquelle les avocats du cabinet attachent une grande importance à développer une stratégie dédiée à chacun des dossiers traités.</p>
            {/* <div id="button-home-wrapper">
              <Button text="Découvrez notre équipe" action="/equipe" />
              <Button text="Découvrez nos domaines" action="/domaines" />
            </div> */}
          </Grid>
          <Grid style={{ width: "100%" }} item sm={12} md={5}>
            <div className="square-layout">
              <div className="square-image-wrapper">
                <Img
                  className="square-image"
                  fluid={data.image.childImageSharp.fluid}
                >
                </Img>
              </div>
            </div>
          </Grid>
        </Grid>
      </section>
      <Link to="/contact" className="call-to-action">
        <h3 id="cta-text">CONSEIL, ACCOMPAGNEMENT, CONTENTIEUX</h3>
      </Link>
    </Layout>
  )
}

export default IndexPage
